<template>
  <div class="login" :class="{noScollForNoLogin_safari:os.safari}">

    <div class="login-form">
        <h2>登录享设计</h2>

        <div class="form form_common">

        
            <van-form ref="l_form" @submit="onSubmit">
                <van-field v-model="mobile" autocomplete="off" maxlength="11" name="mobile" placeholder="手机号码" :rules="[{ required: true, message: '请输入手机号码' },{pattern,message: '手机号码格式错误'}]"/>
                <van-field v-model="password" autocomplete="off" type="password" name="password" placeholder="登录密码" :rules="[{ required: true, message: '请输入登录密码' }]"/>
            </van-form>

            <c-button bClass="btn_login" @btnClick="onLogin">登录</c-button>

            <div class="login-other">
                <p class="cue">没有账号？</p><p class="btn_register" @click="onPageToRegister">马上注册</p><p class="line">|</p><p class="btn_forget" @click="onPageToForget">忘记密码</p>
            </div>

           
        </div>
    </div>


    <c-third-login @otherLogin="onOtherLogin"></c-third-login>

     <van-overlay :show="showDialog" @click="showDialog = false">
        <div class="common-dialog" @click.stop >
            <div class="icon_close" @click="showDialog = false">
                <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
            </div>
            <c-image iUrl="icon-tipoff" iClass="icon" iWidth="36" iHeight="36" ></c-image>
            <h1>微信/微博登录</h1>
            <p class="third_cue">由于第三方限制<br>微信/微博无法直接登录享设计移动端<br>请至PC官网“我的信息”页面<br>绑定邮箱账号和登录密码</p>
            <div class="btn" @click="onPageToBind">已绑定, 马上登录</div>
        </div>
    </van-overlay>    
  </div>
</template>

<script>
import { Field, Form, Overlay } from "vant";
import CButton from "../../components/form/CButton.vue";
import CImage from "../../components/CImage.vue";
import CThirdLogin from "../../components/CThirdLogin.vue";
import { mapActions, mapState } from "vuex";
export default {
    components: {
        [CButton.name]: CButton,
        [CImage.name]: CImage,
        [CThirdLogin.name]: CThirdLogin,
        [Field.name]: Field,
        [Form.name]: Form,
        [Overlay.name]: Overlay
    },
    data() {
        return {
            pattern: /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            mobile: process.env.NODE_ENV == 'development' ? "18017765866" : '', //18017765866
            password: process.env.NODE_ENV == 'development' ? "123456" : '', //123456
            toPage: "",
            showDialog: false
        };
    },
    computed: {
        ...mapState({
            os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestLogin: "user/requestLogin",
        }),
        onPageToBind() {
            this.showDialog = false;
            this.$router.push({
                name: 'bind'
            })
        },
        onOtherLogin() {
            this.showDialog = true;
        },
        onPageToForget() {
            this.$router.push({ name: "forget" });
        },
        onPageToRegister() {
            this.$router.push({ name: "register" });
        },
        onSubmit(e) {
            this.requestLogin(e).then(() => {
                if (this.toPage) {
                    this.$router.push({
                        name: this.toPage,
                        params: this.$route.params,
                    });
                } else {
                    this.$router.go(-1);
                }
            });
        },
        onLogin() {
            this.$refs.l_form.submit();
        },
    },
    created() {
        let _uuid = this.$cookies.isKey("user_id");
        if (_uuid) {
            this.$router.go(-1);
        } else {
            if (this.$route.params.toPage)
                this.toPage = this.$route.params.toPage;
        }
    },
};
</script>

<style>
.login{padding-top: 47px; height: 100vh; background: #fff;}
.login-form{padding:20px 20px 0;}
.login-form h2{font-size: 20px;color: #2E2E30; text-align: center; font-weight: normal;}
.login-form .form{margin-top: 20px;}
.login-form .btn_login{margin-top: 32px;}
.login-other{display: flex; justify-content: center; align-items: center; margin-top: 20px;}
.login-other p{font-size: 14px; margin: 0 4px; color: #2E2E30;}
.login-other .cue{color: #B3B3B3; margin-right:0;}
.login-other .line{color: #B3B3B3;}
.login-other .btn_register{color: #F85659; margin-left:0;height: 17px; border-bottom: 1px solid #F85659;}
.van-overlay{background: transparent !important;}
.login .third_cue{line-height: 21px;}
</style>